import { createRouter, createWebHistory } from 'vue-router'
import homeRoutes from './home'
import aboutRoutes from './about'
import instagramRoutes from './instagram'

const routes = [
  ...homeRoutes,
  ...aboutRoutes,
  ...instagramRoutes,
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

// use this if going to Okta or other Auth mechanism. If using Okta, checkout CSEC
// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (!store.state.auth.status.loggedIn) {
//       router.push({ path: '/signin', name: 'UserSignIn', component: UserSignIn })
//     }
//   }
//   next()
// })

export default router
