export default [
  {
    name: "InstagramLandingPage",
    path: "/social",
    component: () =>
      import(
          /* webpackChunkName: "instagramlandingpage" */ "../views/InstagramView"
      ),
  },
  // {
  //   name: "DataFeedCollectionCreate",
  //   path: "/data_feed_collections/create",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "datafeedcollection" */ "../components/datafeedcollection/Create"
  //     ),
  // },
  // {
  //   name: "DataFeedCollectionUpdate",
  //   path: "/data_feed_collections/edit/:id",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "datafeedcollection" */ "../components/datafeedcollection/Update"
  //     ),
  // },
  // {
  //   name: "DataFeedCollectionShow",
  //   path: "/data_feed_collections/show/:id",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "datafeedcollection" */ "../components/datafeedcollection/Show"
  //     ),
  // },
];
