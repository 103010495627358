// import HomeView from '../views/HomeView.vue'

export default [
  {
    path: '/',
    name: 'home',
    // component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
    // component: HomeView
    redirect: '/social'
  },
];
